import { Component } from '@angular/core';
import { StateService } from '../../services/state.service';
import { Article } from '../../models/article';
import { combineLatest, Observable, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonRouterLink,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-home',
  templateUrl: 'home-page.component.html',
  styleUrls: ['home-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterLink,
    AsyncPipe,
    TranslateModule,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonRouterLink,
  ],
})
class HomePageComponent {
  articles$: Observable<Article[]>;
  frontPageIntro$: Observable<string>;

  constructor(private stateService: StateService) {
    this.articles$ = combineLatest([this.stateService.articles$, this.stateService.secretArticles$]).pipe(
      map(([articles, secretArticles]) => [...articles, ...secretArticles]),
      // sort articles by order
      map((articles) => articles.sort((a, b) => a.order - b.order)),
    );
    this.frontPageIntro$ = stateService.frontPageIntro$;
  }
}

export default HomePageComponent;
