import '@angular/compiler';
import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import * as SentryAngular from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { withInterceptorsFromDi, provideHttpClient, HttpBackend } from '@angular/common/http';
import { routes } from './app/app-routing';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { StateService } from './app/services/state.service';
import { FirebaseAnalyticsService } from './app/services/firebase-analytics.service';
import { StorageService } from './app/services/storage.service';
import { provideIonicAngular, IonicRouteStrategy } from '@ionic/angular/standalone';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import * as Sentry from '@sentry/capacitor';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { isNoMonitorAndProd } from './app/helpers/check-for-monitor-and-prod.helper';
import { Capacitor } from '@capacitor/core';

if (isNoMonitorAndProd() && injectSpeedInsights && !Capacitor.isNativePlatform()) {
  try {
    injectSpeedInsights({});
  } catch (error) {
    // do nothing
  }
}

if (environment.env !== 'development') {
  Sentry.init(
    {
      dsn: 'https://d9829813f6ca46bba3ff2e805c8c6db3@o492374.ingest.sentry.io/5559533',
      environment: environment.env,
      release: environment.release,
      dist: environment.dist,
      autoSessionTracking: true,
      // @ts-ignore
      integrations: [
        new SentryAngular.BrowserTracing({
          routingInstrumentation: SentryAngular.routingInstrumentation,
        }),
      ],
      tracePropagationTargets: ['localhost', /^https:\/\/pregnantwithdiabetes\.vercel\.app/],
      denyUrls: [/utm_source=monitoring/i],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [/Loading chunk 7005 failed/i],
    },
    SentryAngular.init,
  );
}

function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, ['/assets/i18n/']);
}

function initApp(
  storageService: StorageService,
  firebaseAnalyticsService: FirebaseAnalyticsService,
  stateService: StateService,
) {
  return async () => {
    await firebaseAnalyticsService.init();
    await stateService.init();
    return await storageService.init();
  };
}

if (environment.production) {
  enableProdMode();
}

// reload page if error is "isProxied" and browser is Safari
if (window && navigator?.userAgent?.indexOf('Safari') > -1) {
  window.onunhandledrejection = (event) => {
    if (event?.reason?.message?.includes('isProxied')) {
      location.reload();
    }
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
      IonicStorageModule.forRoot({
        name: '__pregnantdb',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      }),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [FirebaseAnalyticsService, StorageService, StateService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular(),
    provideRouter(routes),
  ],
}).catch((err) => console.log(err)); // eslint-disable-line no-console
